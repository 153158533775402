import CardProduct from '../../cardProduct/CardProduct';
// import styles from './StringsGamas.module.css';

const StringsGamas = ({ gamas = {} }) => {
  const { professionals, masters, premiums } = gamas;

  return (
    <div>
      {premiums.length > 0 && (
        <div>
          <h2 className="strings__Title">Premium</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {premiums.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      )}
      {masters.length > 0 && (
        <div>
          <h2 className="strings__Title">Premium</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {masters.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      )}
      {professionals.length > 0 && (
        <div>
          <h2 className="strings__Title">Premium</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {professionals.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StringsGamas;
